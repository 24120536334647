<template>
  <div class="payForm">
    <!-- 充值记录  v-if="!pagination"-->
    <topNav v-if="pagination" :title="title"></topNav>
    <div class="top" v-if="pagination"></div>
    <div class="block-title">
      <strong>{{ $t("充值记录") }}</strong>
    </div>
    <el-table ref="filterTable" :data="payList" style="width: 100%" :height="pagination?'400':''">
      <el-table-column :label="$t('时间')" :width="this.pagination ? '100' : ''">
        <template slot-scope="scope">
          <span class="timestamp" style="white-space: pre-line;">
            {{ scope.row.create_time }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="order_number" :label="$t('订单号')" :formatter="formatterPay"
        :width="this.pagination ? '90' : ''">
        <template slot-scope="scope">
          {{ scope.row.order_number }}
        </template>
      </el-table-column>
      <el-table-column prop="usage_count" :label="$t('使用次数')" :width="this.pagination ? '80' : ''">
      </el-table-column>
      <el-table-column prop="payment_amount" :label="$t('花费')" :width="this.pagination ? '50' : ''">
        <template slot-scope="scope">
          ￥ {{ scope.row.payment_amount / 100 }}
        </template>
      </el-table-column>
      <el-table-column prop="expiration_date" :label="$t('有效期')" width="110">
        <template slot-scope="scope">
          <!-- <el-tag
            :type="scope.row.action == 0 ? 'warning' : 'success'"
            disable-transitions
          > -->
          {{ scope.row.validity }} {{ scope.row.validity == 1 ? $t('天') : $t('多天') }}
          <!-- </el-tag> -->
        </template>
      </el-table-column>
      <el-table-column prop="pay_method" :label="$t('方式')" :width="this.pagination ? '130' : ''">
        <template slot-scope="scope">
          <el-tag :type="scope.row.pay_method | payMethodTagType" disable-transitions>
            <!-- {{ $tc('payMethodType', scope.row.pay_method) }} -->
            {{ $t('payMethodType' + scope.row.pay_method) }}
          </el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination :small="pagination" @size-change="handleSizeChangePay" @current-change="handleCurrentChangePay"
        :current-page="currentPagePay" :page-sizes="[10, 15, 20, 100]" :page-size="payPageSize" :layout="layout"
        :total="payTotal">
      </el-pagination>
    </div>

    <div class="RechargeMobile" v-if="pagination">
      <el-button type="success" @click="$router.push('/recharge')">{{ $t('充值') }}</el-button>
    </div>
    <div class="top" v-if="pagination"></div>
    <div class="top"></div>
  </div>
  <!-- <div v-else>
    <topNav :title="title"></topNav>
    <div class="payMobile">
      <div class="payList">
        <div class="pay" v-for="(i, index) in payList" :key="index">
          <div :class="'payWay' + i.pay_method">
            {{ $tc('payMethodType'),0 }}
          </div>
          <div class="nums">
            <span> {{ $t("使用次数") }}：{{ i.usage_count }} </span>
            <span> {{ $t("花费") }}：{{ i.payment_amount }} </span>
            <span> {{ $t("截止") }}：{{ i.expiration_date }} </span>
            <span> {{ $t("时间") }}：{{ i.create_time }} </span>
            <span> {{ $t("订单号") }}：{{ i.order_number }} </span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import { rechargeTracker } from "../../../config/api";
import topNav from "../../../components/topNav/index.vue";
export default {
  name: "payForm",
  components: { topNav },
  data() {
    return {
      /**充值记录记录 */
      payList: [
        {
          "create_time": "2023-06-26 08:43:32",
          "order_number": "361687740212124537164",
          "usage_count": 20,
          "payment_amount": 0,
          "pay_method": 2,
          "expiration_date": "2023-06-27"
        },
        {
          "create_time": "2023-06-25 10:20:17",
          "order_number": "361687659617189365999",
          "usage_count": 20,
          "payment_amount": 0,
          "pay_method": 2,
          "expiration_date": "2023-06-26"
        }
      ],
      currentPagePay: 1,
      payPageSize: 10,
      payTotal: 5,
      pagination: false, //是否开启小型模式
      layout: "total, sizes, prev, pager, next, jumper",

      /**移动端数据 */
      title: {
        left: true,
        name: this.$t('充值记录'),
        right: false
      }
    };
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    // 获取使用记录
    this.getRechargeTracker();
  },
  filters: {
    // payMethodType(key) {
    // if (key == 1) return `用户购买`;
    // if (key == 2) return `系统奖励`;
    // if (key == 3) return `注册奖励`;
    // },
    payMethodTagType(key) {
      if (key == 1) return "warning";
      if (key == 2) return "";
      if (key == 3) return "success";
      if (key == 4) return "info";
    }
  },
  methods: {
    getRechargeTracker() {
      this.payList = [];
      rechargeTracker(this.currentPagePay, this.payPageSize).then(res => {
        this.payList = res.data.records;
        this.dailyBalance = res.data.balance;
        this.payTotal = res.data.size;
      });
    },
    handleResize() {
      this.pagination = window.innerWidth < 768;
      if (this.pagination) {
      }
      this.layout = "prev, pager, next";
    },
    formatterPay(row, column) {
      return row.desc;
    },
    // 回复记录
    handleSizeChangePay(val) {
      this.payPageSize = val;
      this.getRechargeTracker();
    },
    handleCurrentChangePay(val) {
      this.currentPagePay = val;
      this.getRechargeTracker();
    }
  }
};
</script>
<style lang="less" scoped>
.payForm {
  padding: 0 20px 20px 20px;

  .top {
    margin-top: 20px;
    height: 8rem;
  }

  .block-title {
    margin-top: 20px;
    background-color: #3e4a59;
    padding: 15px;
    line-height: 1;

    >strong {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
    }
  }

  .block {
    margin-bottom: 10px;
  }

  .Recharge{
    .el-button{
      background-color: #0fa47f;
      border: 0;
    }
  }
  .Recharge{
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  @media screen and (max-width: 768px) {
  .block-title {
    margin-top: 0px !important;
    background-color: #3e4a59;
    padding: 15px;
  }
  .el-table /deep/ .el-table__header .cell {
    height: 6rem;
    line-height: 1;
    display: flex;
    align-items: center;
  }
  .el-table /deep/ .el-table__body .cell {
    height: 6rem;
    display: flex;
    align-items: center;
  }

  .el-table,.block {
    margin-bottom: 15px;
  } 
  .RechargeMobile{
    margin: 0 auto;
    width: 100%;
    .el-button{
      width: 100%;
      margin: 0 auto;
      background-color: #0fa47f;
      border: 0;
    }
  }
}}

</style>
<style lang="less" scoped>
/* 修改表格样式 */
.el-table /deep/ .cell {
  padding: 0;
  height: 50px !important;
  line-height: 1;
  display: flex !important;
  align-items: center !important;
}
.el-table  /deep/ .el-table__cell {
  padding: 0;
}
.el-table {
  margin-bottom: 25px;
}
.el-table /deep/ .el-table__row .table__cell .cell > span {
  white-space: inherit !important;
  line-height: 1;
}
.payMobile{
  .payList{
    .pay{
      border: 1px solid #ddd;;
      border-radius: 5px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      margin: 1rem;
      padding: 1rem;
      display: flex;
      align-items: center;
      .payWay1,.payWay2,.payWay3{
        width: 14rem;
        height: 6rem;
        line-height: 1;
        font-size: 2rem;
        text-align: center;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .payWay1{
        border: 2px solid #faecd8;
        border-radius: 5px;
        background-color: #fdf6ec;
        color: #e6a23c;}
      .payWay2{
        border: 2px solid #e2f0ff;
        border-radius: 5px;
        background-color: #ecf5ff;
        color: #46a1ff;}
      .payWay3{
        border: 2px solid #e1f3d8;
        border-radius: 5px;
        background-color: #f0f9eb;
        color: #67c23a;}

      .nums{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between
        padding 0 1rem;
        color: #666;}}}}
.el-table /deep/ .el-table__row .el-table__cell {
  padding: 0 10px;
  .cell>.timestamp {
    white-space: inherit !important;
    line-height: 1;
  }
}
</style>
